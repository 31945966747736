<template>
  <div class="map-container">
    <img src="../assets/images/xh_map.png" alt="Map" ref="map" @click="onMapClick" />
    <template v-for="(marker, index) in markers">
      <div class="marker" v-if="marker.visible" :key="index" :style="{top: marker.y + 'px', left: marker.x + 'px'}"
           @click="onMarkerClick(marker)" @mouseover="marker.showTooltip = true" @mouseleave="marker.showTooltip = false">
        <div class="tooltip" v-if="marker.showTooltip">{{ marker.name }}</div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name:'map',
  data() {
    return {
      markers: [
        { x: 940, y: 200, name: 'Marker 1', visible: true, showTooltip: false },
        { x: 1240, y: 220, name: 'Marker 4', visible: true, showTooltip: false },
        { x: 300, y: 400, name: 'Marker 2', visible: true, showTooltip: false },
        { x: 500, y: 600, name: 'Marker 3', visible: true, showTooltip: false }
      ]
    }
  },
  methods: {
    // onMapClick(event) {
    //   const map = this.$refs.map
    //   const rect = map.getBoundingClientRect()
    //   const x = event.clientX - rect.left
    //   const y = event.clientY - rect.top
    //   const name = `Marker ${this.markers.length + 1}`
    //   this.markers.push({ x, y, name, visible: true, showTooltip: false })
    // },
    onMarkerClick(marker) {
      alert(`Clicked on marker: ${marker.name} (${marker.x},${marker.y})`)
    }
  }

}
</script>

<style scoped>
.map-container {
  position: relative;
}

.marker {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #11020e;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  top: -25px;
  left: -20px;
  padding: 5px;
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  font-size: 12px;
  opacity: 0.8;
  pointer-events: none;
  white-space: nowrap;
}


</style>